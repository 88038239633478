/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import {graphql, Link, useStaticQuery} from 'gatsby';
import {GatsbyImage, getImage, IGatsbyImageData} from 'gatsby-plugin-image';
import {PrimaryButton, SecondaryButton} from '@workday/canvas-kit-react/button';
import {Modal} from '@workday/canvas-kit-react/modal';
import {AccentIcon, SystemIconCircle} from '@workday/canvas-kit-react/icon';
import {borderRadius, colors, space, type} from '@workday/canvas-kit-react/tokens';
import {Box, Flex} from '@workday/canvas-kit-react/layout';

import {
  arrowRightIcon,
  componentIcon,
  imageIcon,
  playCircleIcon,
  tokensIcon,
} from '@workday/canvas-system-icons-web';
import {
  connectedSquareIcon,
  globeEarthIcon,
  handshakeHeartIcon,
  webWindowWriteIcon,
  // workdayAssistantIcon,
} from '@workday/canvas-accent-icons-web';

import SEO from '../components/SEO';
import {H1} from '../components/StyledElements';
import {BorderlessCard, Card, CardProps} from '../components/content/Card';
import {BespokeCurvedSection} from '../components/content/BespokeCurvedSection';
import {BespokeSection, BespokeSplitSection} from '../components/content/BespokeSection';
import {HeadingWithKeyline} from '../components/content/Keyline';
import {ResponsiveFlex} from '../components/content/ResponsiveFlex';
import {WistiaVideo} from '../components/content/WistiaVideo';
import {AnnouncementBanner} from '../components/content/AnnouncementBanner';

import illustration from '../../resources/lottie-animations/home.json';
import AnimatedIllustration from '../components/content/AnimatedIllustration';

import {mqAt, mqGreaterThan} from '../utils/breakpoints';
import {Image} from '../utils/types';
import useSiteMetadata from '../utils/useSiteMetadata';

const GuidelineCard = ({children, ...elemProps}: CardProps) => {
  const styles = {
    border: 'none',
    boxShadow: 'none',
    marginBottom: space.s,

    [mqAt('s')]: {
      width: '100%',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    [mqGreaterThan('s')]: {
      width: `calc((100% - ${space.xl}) / 2)`,
      // Remove bottom margin of last card (change to `-n+2` if there are an
      // even number of cards, or ditch the marginBottom and just use rowGap on
      // the Flex parent if we don't need to support IE11)
      '&:nth-last-of-type(-n+2)': {
        marginBottom: 0,
      },
    },
  };

  return (
    <Card
      css={styles}
      headingStyles={{
        fontSize: type.properties.fontSizes[20],
        fontWeight: type.properties.fontWeights.bold,
      }}
      {...elemProps}
    >
      {children}
    </Card>
  );
};

const Page = () => {
  const {
    allFile: {edges: images},
  } = useStaticQuery(graphql`
    query {
      allFile(filter: {relativePath: {regex: "/^home/"}}) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
        }
      }
    }
  `);
  const ckGithubImg = images.find((img: Image) => img.node.name === 'home-canvas-kit-github')?.node;

  // TODO: Workaround for TS cannot find module error, consider adding custom
  // type definition instead.
  // https://www.gatsbyjs.com/plugins/gatsby-plugin-react-svg/#using-with-typescript
  // https://annacoding.com/article/5I7om6mCrW25KeA4ObeyMJ/Two-ways-to-solve:-Cannot-find-SVG-module-error-when-compiling-Typescript-in-React-application
  const v11BannerLrg =
    require('../../resources/announcement-banners/v-11-announcement-banner-lg.svg') as {
      default: string;
    };
  const v11BannerSml =
    require('../../resources/announcement-banners/v-11-announcement-banner-sm.svg') as {
      default: string;
    };

  const config = useSiteMetadata();

  // We cannot use ContentBody to wrap the Home page since the Home page has
  // the Our Design System section which has a background that spans the full
  // width of the page (ContentBody has horizontal margins that would prevent
  // the background of children elements from spanning the full width of the
  // screen). Instead, we build responsive behavior into the BespokeSection
  // and BespokeCurvedSection.
  return (
    <>
      <Box paddingBottom={120}>
        <SEO title="Home" />
        <BespokeSection hasHorizontalPadding={false}>
          <AnnouncementBanner
            largeBanner={v11BannerLrg.default}
            smallBanner={v11BannerSml.default}
            link="/help/upgrade-guides/canvas-v11-upgrade-guide"
            alt="Out now - Canvas version 11 release"
          />
        </BespokeSection>
        <BespokeSplitSection
          startContent={
            <>
              <H1>Workday Canvas Design System</H1>
              <p
                css={{
                  ...type.levels.body.large,
                  marginTop: space.l,
                  marginBottom: space.l,
                }}
              >
                Canvas is an open source design system that consists of reusable UI elements,
                resources, and guidelines that enable teams to create scalable, unified experiences.
              </p>
              {/* TODO: Shouldn't use gap due to lack of IE support */}
              <Flex flexWrap="wrap" css={{gap: space.s}}>
                <PrimaryButton
                  as={Link}
                  css={{textDecoration: 'none'}}
                  to="/get-started/introduction"
                >
                  Get Started
                </PrimaryButton>
                <Modal>
                  <Modal.Target
                    as={SecondaryButton}
                    css={{textDecoration: 'none'}}
                    icon={playCircleIcon}
                    iconPosition="start"
                  >
                    Watch the Video
                  </Modal.Target>
                  <Modal.Overlay>
                    <Modal.Card paddingRight={0} paddingBottom={0} paddingLeft={0} width={600}>
                      <Modal.CloseIcon aria-label="Close" />
                      <Modal.Body>
                        <WistiaVideo
                          id="3myu02jkp5"
                          css={{
                            marginTop: space.s,
                            marginBottom: 0,
                            iframe: {
                              borderBottomLeftRadius: borderRadius.l,
                              borderBottomRightRadius: borderRadius.l,
                            },
                          }}
                        />
                      </Modal.Body>
                    </Modal.Card>
                  </Modal.Overlay>
                </Modal>
              </Flex>
            </>
          }
          endContent={
            // Add a max width to the illustration to prevent it from getting
            // comically large.
            //
            // Scale the animated illustration to compensate for the padding
            // around the illustration (so it fills the available space). Scaling
            // is preferred over using negative margins since it avoids layout
            // shift after the illustration loads in during production.
            <AnimatedIllustration
              css={{maxWidth: '480px', transform: 'scale(1.18)', margin: '0 auto'}}
              data={illustration}
            />
          }
          reverseColumn={true}
        />
        <BespokeCurvedSection>
          <HeadingWithKeyline keylineText="Explore">Our Design System</HeadingWithKeyline>
          <ResponsiveFlex columnIfLessThan="xl">
            <BorderlessCard
              headingText="Tokens"
              icon={<SystemIconCircle icon={tokensIcon} background={colors.cantaloupe500} />}
              linkText="Learn More"
              linkTo="/styles/tokens/overview"
            >
              <p>
                Learn more about the smallest pieces of our design system that serve as the
                foundation of our system.
              </p>
            </BorderlessCard>
            <BorderlessCard
              headingText="Assets"
              icon={<SystemIconCircle icon={imageIcon} background={colors.watermelon400} />}
              linkText="View and Download"
              linkTo="/styles/assets/overview"
            >
              <p>
                View and download all the Workday icons, illustrations, and brand assets you’ll need
                to create awesome things.
              </p>
            </BorderlessCard>
            <BorderlessCard
              headingText="Components"
              icon={<SystemIconCircle icon={componentIcon} background={colors.blueberry400} />}
              linkText="Read Guidelines"
              linkTo="/components/overview"
            >
              <p>
                See examples and read usage guidelines for Buttons, Containers, Inputs, and more.
              </p>
            </BorderlessCard>
          </ResponsiveFlex>
          <ResponsiveFlex
            columnIfLessThan="m"
            equalChildren={false}
            alignItems="center"
            justifyContent="center"
            marginTop="xl"
          >
            <GatsbyImage
              image={getImage(ckGithubImg) as IGatsbyImageData}
              alt="Canvas GitHub illustration"
              css={{width: '250px'}}
            />
            <Box>
              <p css={{...type.levels.body.small}}>View the Workday Canvas Kit on GitHub</p>
              <SecondaryButton
                as="a"
                css={{marginTop: space.s, textDecoration: 'none'}}
                href="https://github.com/Workday/canvas-kit"
                icon={arrowRightIcon}
                iconPosition="end"
              >
                Start Building
              </SecondaryButton>
            </Box>
          </ResponsiveFlex>
        </BespokeCurvedSection>
        <BespokeSection>
          <HeadingWithKeyline keylineText="Discover">Our Guidelines</HeadingWithKeyline>
          <p css={type.levels.body.large}>
            Our design principles of Simple, Fast, and Clever shape the way we work every day. They
            help us make tough design decisions and distill many iterations into the best solution.
            Read our guidelines to understand the foundation.
          </p>
          <Flex flexWrap="wrap" justifyContent="space-between" marginTop="xl">
            {config.internal && (
              <GuidelineCard
                headingText="Patterns"
                icon={<AccentIcon icon={connectedSquareIcon} />}
                linkText="Explore Patterns"
                linkTo="/patterns/overview"
              >
                <p>Guidelines on reusable solutions built to respond to common user scenarios.</p>
              </GuidelineCard>
            )}
            <GuidelineCard
              headingText="Content Style Guidelines"
              icon={<AccentIcon icon={webWindowWriteIcon} />}
              linkText="Explore Content Styleguide"
              linkTo="/guidelines/content/overview"
            >
              <p>
                From grammar, style to punctuation and beyond, our Content Style Guide helps you
                along the way.
              </p>
            </GuidelineCard>
            <GuidelineCard
              headingText="Accessibility"
              icon={<AccentIcon icon={handshakeHeartIcon} />}
              linkText="Learn about Accessibility"
              linkTo="/guidelines/accessibility/overview"
            >
              <p>Accessible design provides better user experiences for everyone.</p>
            </GuidelineCard>
            <GuidelineCard
              headingText="Globalization"
              icon={<AccentIcon icon={globeEarthIcon} />}
              linkText="Learn about Globalization"
              linkTo="/guidelines/globalization/overview"
            >
              <p>
                Create innovative ways to improve global functionality and create inclusive
                experiences.
              </p>
            </GuidelineCard>
            {/* <GuidelineCard
              // TODO: Figure out why workdayCircleIcon from @workday/third-party-icons-web breaks the CI
              headingText="Brand"
              icon={<AccentIcon icon={workdayAssistantIcon} />}
              linkText="Check out our Brand Guide"
              // TODO: Fix this link
              linkTo="/"
            >
              <p>
                Explore our brand guidelines to ensure you are following Workday’s visual styles and
                identity.
              </p>
            </GuidelineCard> */}
          </Flex>
        </BespokeSection>
      </Box>
    </>
  );
};

Page.title = 'Home';
Page.centerFooter = true;
Page.suppressTopJump = true;

export default Page;
